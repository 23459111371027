import { styled } from 'styled-components';

import { headerHeight } from '@src/theme/common';

export const HeaderContainer = styled.header`
  ${headerHeight};
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing(2.5)};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.graphite[30]};
`;

export const LogoWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StepTitle = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSize(18)};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
`;

export const PointsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Point = styled.div`
  width: 24px;
  height: 3px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.grey[40]};

  &[data-active='true'] {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const BackButton = styled.button`
  position: absolute;
  align-self: start;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const SwitchLangButton = styled.button`
  position: absolute;
  right: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.fontSize(16)};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;

  &[data-direction='rtl'] {
    left: ${({ theme }) => theme.spacing(4)};
    right: unset;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    right: ${({ theme }) => theme.spacing(2)};

    &[data-direction='rtl'] {
      left: ${({ theme }) => theme.spacing(2)};
      right: unset;
    }
  }
`;
