import { Outlet } from 'react-router-dom';

import Footer from '@src/containers/Footer';
import Header from '@src/containers/Header';
import Registration from '@src/containers/Registration';

import useClarity from './hooks/useClarity';
import { MainContainerStyled, RootContainerStyled } from './styles';

export default function MainLayout(): JSX.Element {
  useClarity();

  return (
    <RootContainerStyled>
      <Registration />
      <Header />
      <MainContainerStyled>
        <Outlet />
      </MainContainerStyled>
      <Footer />
    </RootContainerStyled>
  );
}
