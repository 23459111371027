import { useMatch } from 'react-router-dom';
import {
  SVGIcon,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import Logo from '@src/assets/logo-llama.svg';
import { useStepsHandler } from '@src/hooks/useStepsHandler';
import { Path } from '@src/types/routes';

import {
  BackButton,
  HeaderContainer,
  LogoWrapper,
  Point,
  PointsContainer,
  StepContainer,
  StepTitle,
  SwitchLangButton,
} from './styles';

export default function Header(): JSX.Element {
  const { langData, selectLang } = useTranslations();
  const { stepsData } = useStepsHandler();
  const navigate = useQueryNavigate();
  const activeTitle = stepsData.find((step) => step.status === 'active')?.name;

  const isClientDataPage = !!useMatch(Path.clientData);

  function navigateToMainPage(): void {
    navigate(Path.main);
  }

  function selectLangHandler() {
    const { lang } = langData;

    if (lang === 'en') {
      selectLang('ar');
    } else {
      selectLang('en');
    }
  }

  const langTitle = langData.lang === 'en' ? 'عربي' : 'EN';

  return (
    <HeaderContainer>
      {isClientDataPage && (
        <BackButton
          role="button"
          tabIndex={0}
          aria-label="back to main"
          onClick={navigateToMainPage}
        >
          <SVGIcon name="chevron" rotate={90} />
        </BackButton>
      )}
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <StepContainer>
        <StepTitle>{activeTitle}</StepTitle>
        <PointsContainer>
          {stepsData.map((step, index) => {
            const isActive =
              step.status === 'active' || step.status === 'completed';
            return <Point key={index} data-active={isActive} />;
          })}
        </PointsContainer>
      </StepContainer>
      <SwitchLangButton
        data-direction={langData.direction}
        onClick={selectLangHandler}
      >
        {langTitle}
      </SwitchLangButton>
    </HeaderContainer>
  );
}
